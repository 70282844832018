import i18n from 'i18next'
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// if we want to always show one language as default, no matter what the browser says
// if you want to use the browser language, just remove the CustomDetector in the detection options
export const defaultLanguage = 'de'

const myDetector: CustomDetector = {
  name: 'myDetectorsName',

  lookup(options: DetectorOptions) {
    return (
      (options.lookupLocalStorage ? localStorage.getItem(options.lookupLocalStorage) : undefined) || defaultLanguage
    )
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(myDetector)

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: { order: ['myDetectorsName'] },
    debug: false,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `I am ${import.meta.env.VITE_SITE_NAME} and I know (almost) everything published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1>.`,
          },
          from: 'from',

          results: {
            initial: `
We continue to work to improve, expand, and regularly update ${import.meta.env.VITE_SITE_NAME}. 
                      We therefore appreciate any feedback. Or even spreading the word if you are satisfied with ${
                        import.meta.env.VITE_SITE_NAME
                      }. `,
            initialEmpty: ``,
          },
          question: {
            label: 'Your Question',
            placeholder: 'Ask me anything about the administration of the City of Lucerne.',
            buttonText: 'Ask Question',
            microphoneLabel: 'Ask Question (with Microphone)',
            microphoneListening: 'Microphone: Hold down the button and speak',
          },
          answers: {
            oneMoment: 'One moment please...',
            serverConnectionError: 'Connection to the server lost :( Please try again soon.',
            otherModelButton: 'Try with more context (experimental)',
            wasItUseful: 'Was the answer useful?',
            yes: 'Yes',
            no: 'No',
            giveFeedback: 'Give Feedback',
            readHints: 'Read Hints and Limitations',
            letsTryToImprove: "Let's try to improve the answer!",
            improvePrompt: 'Refine your question and try again',
            thanksForFeedback: 'Thanks for your feedback!',
            unhappy: 'Unhappy with the answer?',
            not_considered_articles: 'Links not considered for the answer (but possibly still relevant):',
            considered_articles: 'Links considered for the answer:',
            answer: 'Answer',
            searchresults: 'Search Results',
          },
          hints: {
            title: 'Hints and Limitations',
            notesTitle: 'Hints',
            note1:
              'The bot does not remember previously asked questions. You need to enter the question again for refinements.',
            note2:
              'It is better to ask complete questions instead of just keywords. Sometimes, reformulating the question may result in a better answer.',
            note3:
              'Providing the current year (e.g., 2023) or asking more specific questions can yield more up-to-date answers.',
            note4: 'If no answer is received or the answer abruptly ends, it is best to try again.',
            note5:
              "We store your questions and answers with us so that we can improve the service over time. That's why we appreciate it if you give us feedback; it helps us tremendously. [Privacy Policy](/privacy)",
            note6:
              'All information is provided without warranty. Please verify the information on the official website of the City of Lucerne.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} knows (almost) everything that was published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> on {{lastUpdate}}.`,
            disclaimerText: 'This app is not associated with the City of Lucerne.',
          },
          contact: {
            title: 'Contact and Feedback',
            emailLabel: 'Your E-Mail (optional):',
            messageLabel: 'Your message:',
            submitButton: 'Submit',
            feedbackReceived: 'Feedback received, thank you!',
            feedbackReceivedError: 'Error while sending feedback, please try the other means to reach us.',
            emailText: 'Oder senden Sie einsend ann email at chregu@liip.ch',
            moreContact: 'More contact options at',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post about the project</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} is not affiliated with the City of Lucerne.`,
            selectLanguage: 'Select Language',
          },
        },
      },
      de: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Ich bin ${import.meta.env.VITE_SITE_NAME} und ich weiss (fast) alles,
             was auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert ist.`,
          },
          from: 'vom',
          question: {
            label: 'Deine Frage',
            placeholder: 'Frag mich etwas über die Verwaltung der Stadt Luzern.',
            buttonText: 'Frage stellen',
            microphoneLabel: 'Frage stellen (mit Mikrofon)',
            microphoneListening:
              'Mikrofon: Halten Sie den Knopf gedrückt und sprechen Sie. Schweizerdeutsch geht auch. Meistens.',
          },
          results: {
            initial: `
Diese Applikation dient dazu, die Antworten des KI-Assistenten auf ihre Richtigkeit hin zu überprüfen und gegebenfalls Anpassungen vorzunehmen. Sie entspricht nicht der endgültigen Fassung, die im neuen Web-Auftritt eingebunden wird.

Wir bitten Sie um Folgendes:

* Stellen Sie dem Assistenen einige Fragen zu ihrem Themen- oder Organisationsbereich. Je mehr Fragen Sie stellen, desto breiter ist die Datenbasis für die Verbesserung der Antwort-Qualität.
* Beurteilen Sie die Antwort einerseits hinsichtlich ihrer inhaltlichen Qualität und andererseits der Tragweite der Auswirkungen einer Falschinformation.
* Über sonstige Anmerkungen und Feedbacks freuen wir uns ebenfalls.

Die so gesammelten Frage-Antwort-Paare werden zusammen mit Ihren Beurteilungen von einem Team ausgewertet, um zu entscheiden ob die Verbesserungen bei der KI vorgenommen werden müssen oder allenfalls die Inhalte  nicht ganz klar bzw. eindeutig formuliert sind.

                       `,
            initialEmpty: ``,
          },
          answers: {
            oneMoment: 'Einen Moment bitte...',
            serverConnectionError: 'Verbindung zum Server abgebrochen :( Bitte bald nochmals probieren.',
            otherModelButton: 'Mit mehr Kontext versuchen (Experimentell)',
            wasItUseful: 'War die Antwort hilfreich?',
            yes: 'Ja',
            no: 'Nein',
            giveFeedback: 'Feedback geben',
            readHints: 'Hinweise und Einschränkungen lesen',
            letsTryToImprove: 'Lass uns versuchen, die Antwort zu verbessern!',
            improvePrompt: 'Frage verfeinern und nochmals probieren',
            thanksForFeedback: 'Danke für Ihr Feedback!',
            unhappy: 'Unzufrieden mit der Antwort?',
            not_considered_articles: 'Für die Antwort nicht berücksichtigte Links (aber evt. doch relevant):',
            considered_articles: 'Für die Antwort berücksichtigte Links:',
            answer: 'Antwort',
            searchresults: 'Suchresultate',
          },
          hints: {
            title: 'Hinweise und Einschränkungen',
            notesTitle: 'Hinweise',
            note1:
              'Der Bot merkt sich einmal gestellte Fragen nicht. Für Verfeinerungen muss die Frage nochmals eingegeben werden.',
            note2:
              'Es ist besser ganze Fragen zu stellen, anstatt nur Stichwörter. Manchmal hilft es auch eine Frage anders zu formulieren, um eine perfektere Antwort zu erhalten.',
            note3:
              'Oder die Jahreszahl (z.B. 2023) mitzuliefern für aktuellere Antworten. Oder generell spezifischer zu fragen.',
            note4: 'Sollte keine Antwort kommen oder die Antwort abrupt enden, am Besten nochmals probieren.',
            note5:
              'Wir arbeiten kontinuierlich daran den Service zu verbessern. Komm also später wieder vorbei, wenn du noch nicht ganz zufrieden warst. Und darum speichern wir auch deine Fragen und Antworten bei uns, damit wir davon lernen können. Wir freuen uns auch, wenn du uns Feedback gibst, das hilft uns enorm. [Datenschutzerklärung](/privacy)',
            note6:
              'Alle Angaben ohne Gewähr. Bitte überprüfe die Information auf den offiziellen Webseiten der Stadt Luzern.',
            limitationsTitle: 'Einschränkungen',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} weiss (fast) alles, 
            was am {{lastUpdate}} auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert war.`,
            disclaimerText: '',
          },
          contact: {
            title: 'Kontakt und Feedback',
            emailLabel: 'Deine E-Mail (optional):',
            messageLabel: 'Deine Nachricht:',
            submitButton: 'Abschicken',
            feedbackReceived: 'Feedback erhalten, danke!',
            feedbackReceivedError: 'Da ist was schief gelaufen, bitte kontaktiere uns auf anderen Wegen.',
            emailText: 'Oder senden Sie eine Mail an chregu@liip.ch',
            moreContact: 'Mehr Kontaktmöglichkeiten auf',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post über das Projekt</3>',
            disclaimer: ``,
            selectLanguage: 'Sprache auswählen',
          },
        },
      },
      fr: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Je suis ${
              import.meta.env.VITE_SITE_NAME
            } et je connais (presque) tout ce qui a été publié sur <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1>.`,
          },
          from: 'du',

          results: {
            initial: `
Nous continuons à travailler dur pour améliorer, agrandir et mettre à jour régulièrement ${
              import.meta.env.VITE_SITE_NAME
            }. 
                      Nous apprécions donc tous les retours. Ou même en parler autour de vous si vous êtes satisfait de ${
                        import.meta.env.VITE_SITE_NAME
                      }. `,
            initialEmpty: ``,
          },
          question: {
            label: 'Votre Question',
            placeholder: "Demandez-moi tout ce que vous voulez savoir sur l'administration de la ville de Lucerne.",
            buttonText: 'Poser la question',
            microphoneLabel: 'Poser la question (avec microphone)',
            microphoneListening: 'Microphone: Maintenez le bouton enfoncé et parlez',
          },
          answers: {
            oneMoment: "Un moment s'il vous plaît...",
            serverConnectionError: 'Connexion au serveur interrompue :( Veuillez réessayer bientôt.',
            otherModelButton: 'Essayez avec plus de contexte (expérimental)',
            wasItUseful: 'La réponse vous était utile?',
            yes: 'Oui',
            no: 'Non',
            giveFeedback: 'Donner un feedback',
            readHints: 'Lire les astuces et limitations',
            letsTryToImprove: 'Essayons d’améliorer les choses!',
            improvePrompt: 'Affiner la question et réessayer',
            thanksForFeedback: 'Merci pour votre feedback!',
            unhappy: 'Insatisfait de la réponse?',
            not_considered_articles:
              'Liens non pris en compte pour la réponse (mais éventuellement toujours pertinents):',
            considered_articles: 'Liens pris en compte pour la réponse:',
            answer: 'Réponse',
            searchresults: 'Résultats de recherche',
          },
          hints: {
            title: 'Astuces et Limitations',
            notesTitle: 'Astuces',
            note1: `${
              import.meta.env.VITE_SITE_NAME
            } ne se souvient pas des questions posées. Si la réponse ne vous convient pas, reprenez votre question et affinez-la pour améliorer la qualité de la réponse.`,
            note2:
              'Il est préférable de poser des questions complètes plutôt que simplement des mots-clés. Parfois, reformuler la question peut donner une meilleure réponse.',
            note3:
              "Fournir l'année en cours (par exemple, 2023) ou poser des questions plus spécifiques peut donner des réponses plus à jour.",
            note4: "Si aucune réponse n'est reçue ou si la réponse se termine brusquement, relancez votre recherche.",
            note5:
              "Nous stockons vos questions et réponses chez nous afin de pouvoir améliorer le service au fil du temps. C'est pourquoi nous apprécions si vous nous donnez des retours ; cela nous aide énormément. [Politique de Confidentialité](/privacy)",
            note6:
              'Toutes les informations sont fournies sans garantie. Veuillez vérifier les informations sur le site officiel de la ville de Lucerne.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} connaît uniquement ce qui a été publié sur <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> avant le {{lastUpdate}}.`,
            disclaimerText: "Cette application n'est pas associée à la ville de Lucerne.",
          },
          contact: {
            title: 'Contact et Commentaires',
            emailLabel: 'Votre E-Mail :',
            messageLabel: 'Votre message :',
            submitButton: 'Envoyer',
            feedbackReceived: 'Merci pour votre message!',
            feedbackReceivedError: 'Quelque chose s’est mal passé, veuillez nous contacter par d’autres moyens.',
            emailText: 'Ou envoyez un email à chregu@liip.ch',
            moreContact: "Plus d'options de contact sur",
          },
          footer: {
            credits: 'Propulsé par <1>Liip</1> — <3>Article sur le projet</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} n'est pas affilié à la ville de Lucerne.`,
            selectLanguage: 'Sélectionner la Langue',
          },
        },
      },
    },
  })

export default i18n
